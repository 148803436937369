import { get, identity, orderBy } from 'lodash-es';

import { Company } from 'src/global/models';

export const useCompanyLocation = (company?: Company): string | null => {
  if (!company) return null;

  const location = company.hierarchicalLocation;
  const locationFormattedNames = company.locationFormattedNames;
  if (location && locationFormattedNames.length) {
    try {
      const parents = location.parents.map((parent) => ({
        ...parent,
        formattedName: locationFormattedNames.find(
          (item) => item.locationId === parent.id
        )?.formattedName,
      }));

      const newLoc = {
        ...location,
        formattedName: locationFormattedNames.find(
          (item) => item.locationId === location.id
        )?.formattedName,
        parents,
      };
      return [
        newLoc.formattedName,
        ...orderBy(newLoc.parents, 'level', 'desc').map(
          (item) => item.formattedName
        ),
      ]
        .filter(identity)
        .join(', ');
    } catch (_error) {
      console.error(_error);
      return '';
    }
  }
  const cityName = get(company, 'links.city.name');
  const countryName = get(company, 'links.country.name');
  return cityName ? `${cityName}, ${countryName}` : countryName;
};
