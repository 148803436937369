export const getVerifiedArticalUrl = (language: string) => {
  switch (language) {
    case 'vi':
      return 'https://help.glints.com/hc/vi/articles/20241109298073-C%C3%A1c-C%C3%B4ng-ty-%C4%91%C3%A3-%C4%91%C6%B0%E1%BB%A3c-x%C3%A1c-minh';
    case 'id':
      return 'https://help.glints.com/hc/id-id/articles/20241109298073-Definisi-Perusahaan-Terverifikasi-di-Glints-untuk-pelamar';
    default:
      return 'https://help.glints.com/hc/en-us/articles/20241109298073-Definition-of-Verified-Company';
  }
};
