import { ScreenSize } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { Greyscale } from 'glints-aries/lib/Utils/Colors';
import styled from 'styled-components';

import GlintsContainer from 'src/components/GlintsContainer';

export const Main = styled.div`
  display: flex;
  position: sticky;
  flex-direction: column;
  box-shadow: 0px -1px 0px 0px #d4d5d8 inset;
  width: 100%;
  background-color: ${Neutral.B100};

  top: 70px;
  z-index: 2;
`;

export const Container = styled(GlintsContainer)`
  @media (min-width: ${ScreenSize.desktopL}px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${ScreenSize.mobileL}px) {
    width: 100%;
  }
`;

export const CollapsibleContainer = styled(Container)<{ isScrolling: boolean }>`
  max-height: ${({ isScrolling }) => (isScrolling ? '0px' : '500px')};
  opacity: ${({ isScrolling }) => (isScrolling ? 0 : 1)};
  overflow: hidden;

  transition:
    max-height 0.5s ease-in-out,
    opacity 0.5s ease-in-out;

  @media (max-width: ${ScreenSize.mobileL}px) {
    transition:
      max-height 0.3s ease-in-out,
      opacity 0.2s ease-in-out;
  }
`;

export const TabsListContainer = styled(Container)`
  border-bottom: 1px solid ${Neutral.B85};
`;

export const Header = styled.section`
  background: ${Greyscale.white};

  padding-bottom: 24px;

  & + & {
    margin-top: 22px;
  }

  @media (min-width: ${ScreenSize.desktopS}px) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'logo overview'
      'footer footer';
    padding: 40px 0;
  }

  @media (min-width: ${ScreenSize.desktopM}px) {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
    z-index: 2;
  }
`;

export const TabsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;

  @media (max-width: ${ScreenSize.mobileL}px) {
    width: 100%;
  }
`;

export const TabText = styled(Typography).attrs({ variant: 'body1' })`
  width: 100%;
`;

export const TabList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  height: 56px;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const TabListItem = styled.li`
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: ${Neutral.B40};
  white-space: nowrap;

  &.active {
    color: ${Blue.S99};
  }

  &.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: 88px;
    height: 3px;
    background-color: ${Blue.S99};
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
  }

  &:not(.active)::after {
    opacity: 0;
  }
`;
